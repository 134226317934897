import './PhotoGallery.css';
import { useWindowWidth } from '../../hooks/useWindowSize';
import Photo from '../Photo/Photo';
import Carousel from '../Carousel/Carousel';
import { useEffect, useState } from 'react';
import { MEDIUM_SCREEN } from '../../utils/consts';
import { images, title } from './consts';

export default function PhotoGallery() {
  const width = useWindowWidth();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    width <= MEDIUM_SCREEN ? setIsMobile(true) : setIsMobile(false);
  }, [width]);

  return (
    <>
      {isMobile ? (
        <Carousel images={images} title={title} />
      ) : (
        <Photo images={images} title={title} />
      )}
    </>
  );
}
