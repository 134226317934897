import leadImage from '../../images/vovaphint_1000.jpg';
import './Lead.css';
import { text, title } from './consts';

export default function Lead() {
  return (
    <section className='lead'>
      <img src={leadImage} alt='Владимир Филиппов' className='lead__image' />

      <article className='lead__article'>
        <h1 className='lead__title'>{title}</h1>
        <p className='lead__text'>{text}</p>
      </article>
    </section>
  );
}
