export const title = 'Обо мне';

export const aboutText = [
  `Добрый день!`,
  `Меня зовут Владимир. Я профессиональный фотограф недвижимости.`,
  `Я специализируюсь на создании фотографий, которые помогут продемонстрировать привлекательность вашей недвижимости потенциальным клиентам.`,
  `Моя цель - подчеркнуть уникальность и красоту каждого дома, квартиры или коммерческого помещения с помощью хороших фотографий.`,
  `В моём портфолио есть разнообразные работы от квартир и жилых домов до коммерческих объектов. Со мной работают агентства недвижимости, частные агенты и собственники помещений.`,
  `Хотите лучшим образом преподнести клиенту свой объект недвижимости? Тогда обращайтесь ко мне для создания хороших фотографий!`,
];
