import { useState } from 'react';
import './MessageLink.css';

export default function MessageLink({ item }) {
  const { title, link, imgWhite, imgBlack } = item;

  const [isHovering, setIsHovering] = useState(false);

  const onMouseEnter = () => {
    setIsHovering(!isHovering);
  };

  const onMouseLeave = () => {
    setIsHovering(!isHovering);
  };

  return (
    <li className='message__item'>
      {title}
      <a
        href={link}
        target='_blank'
        rel='noopener noreferrer'
        className='message__link'
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <img src={isHovering ? imgBlack : imgWhite} alt={title} className='message__image' />
      </a>
    </li>
  );
}
