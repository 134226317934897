import telegramIconWhite from '../images/icon-telegram-white.png';
import telegramIconBlack from '../images/icon-telegram-black.png';
import whatsAppIconWhite from '../images/icon-whatsapp-white.png';
import whatsAppIconBlack from '../images/icon-whatsapp-black.png';

export const logo = 'Владимир Филиппов';
export const main = 'Главная';

const blogUrl = 'блог';
const encodedBlogUrl = encodeURIComponent(blogUrl);
export const decodedBlogUrl = decodeURIComponent(encodedBlogUrl);

const servicesUrl = 'услуги';
const encodedServicesUrl = encodeURIComponent(servicesUrl);
export const decodedServicesUrl = decodeURIComponent(encodedServicesUrl);

const contactsUrl = 'контакты';
const encodedСontactsUrl = encodeURIComponent(contactsUrl);
export const decodedСontactsUrl = decodeURIComponent(encodedСontactsUrl);

export const navLinksList = [
  {
    id: '0',
    link: decodedBlogUrl,
    title: 'Блог',
  },
  {
    id: '1',
    link: decodedServicesUrl,
    title: 'Услуги',
  },
  {
    id: '2',
    link: decodedСontactsUrl,
    title: 'Контакты',
  },
];

export const socialLinks = [
  {
    id: 0,
    title: 'WhatsApp',
    link: 'https://wa.me/79256126449',
    imgWhite: whatsAppIconWhite,
    imgBlack: whatsAppIconBlack,
  },
  {
    id: 1,
    title: 'Telegram',
    link: 'https://t.me/maxcaxap/',
    imgWhite: telegramIconWhite,
    imgBlack: telegramIconBlack,
  },
];

export const EXP_YEARS = new Date().getFullYear() - 2016;

export const SMALL_SCREEN = 768;
export const MEDIUM_SCREEN = 1024;
export const FULL_SCREEN = 1280;
