import './Photo.css';

export default function Photo({ images, title }) {
  const listItems = images.map((item, index) => (
    <li key={index} className='photo__item'>
      <img src={item} alt='Пример съёмки интерьера' className='photo__image' />
    </li>
  ));

  return (
    <>
      <section className='photo'>
        <h2 className='photo__title'>{title}</h2>
        <ul className='photo__list'>{listItems}</ul>
      </section>
    </>
  );
}
