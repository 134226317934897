import './Carousel.css';
import { useState } from 'react';

export default function Carousel({ images, title }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [touchPosition, setTouchPosition] = useState(null);

  const nextSlide = () => {
    setCurrentSlide(currentSlide === images.length - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? images.length - 1 : currentSlide - 1);
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 4) {
      nextSlide();
    }

    if (diff < -4) {
      prevSlide();
    }

    setTouchPosition(null);
  };

  return (
    <section className='carousel'>
      <h2 className='carousel__title'>{title}</h2>

      <article className='carousel__article'>
        <button className='left-arrow' onClick={prevSlide}>
          &lt;
        </button>
        <img
          className='carousel__image'
          src={images[currentSlide]}
          alt={`Слайд ${currentSlide + 1}`}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        />

        <button className='right-arrow' onClick={nextSlide}>
          &gt;
        </button>
      </article>
    </section>
  );
}
