import './FooterBottom.css';
import { logo } from '../../utils/consts';

export default function FooterBottom() {
  const date = new Date().getFullYear();

  return (
    <p className='footer__bottom'>
      {date}&nbsp;©&nbsp;{logo}
    </p>
  );
}
