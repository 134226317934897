export const title = 'Часто задаваемые вопросы';

export const ASKED_QUESTIONS = [
  {
    id: '0',
    subtitle: 'Как долго длится фотосъёмка интерьера?',
    text: `Как правило количество времени необходимое для фотосъëмки объекта напрямую зависит от количества комнат, которые нужно снять и количества фотографий, которые нужно сделать в каждой комнате. Среднее время одной подготовленной для съëмки комнаты примерно 3-5 минут. Соответственно на съёмку 1-комнатной квартиры где есть санузел, кухня, жилая комната и прихожая уйдëт около 12-20 минут.`,
  },
  {
    id: '1',
    subtitle: 'Какие услуги входят в пакет съёмки?',
    text: `В услугу фотосъёмки входят: выезд в пределах МКАД, съёмка помещений объекта, видов из окон, входной группы, прилегающей территории и фасада дома, а также цветокоррекция фотографий и хранение готовых и исходных RAW файлов в облаке.`,
  },
  {
    id: '2',
    subtitle: 'Какое оборудование используете для фотосъёмки?',
    text: `Я использую профессиональную фотокамеру с полнокадровой матрицей, широкоугольный объектив и штатив, которые помогают мне создавать качественные изображения в любых условиях съëмки.`,
  },
  {
    id: '3',
    subtitle: 'Как подготовить объект недвижимости к съёмке?',
    text: `Перед фотосъёмкой интерьера желательно провести уборку объекта недвижимости, спрятать все лишние и личные вещи, фотографии, религиозные принадлежности, а также кормушки/туалеты для домашних животных.`,
  },
  // {
  //   id: '4',
  //   subtitle: 'Как обычно проходит процесс фотосъёмки и обработки фотографий?',
  //   text: `Я делаю фотографии, которые просит клиент или снимаю максимум возможных ракурсов. После съёмки отправляю необработанные кадры заказчику. Заказчик выбирает изображения, которые нужно обработать. Я делаю обработку и отправляю готовые фотографии с водяным знаком. После оплаты работы отправляю готовые фотографии без водяного знака.`,
  // },
];
