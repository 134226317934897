export const title = 'Мои преимущества';

export const dignityList = [
  {
    id: '0',
    alt: 'Гибкость',
    title: 'Гибкость',
    text: `Согласуем удобное время для проведения фотосъёмки. Всегда готов пойти на встречу.`,
  },
  {
    id: '1',
    alt: 'Профессионализм',
    title: 'Профессионализм',
    text: `У меня большой опыт работы в области фотографии. Вы можете быть уверены в том, что получите отличные кадры с фотосъёмки.`,
  },
  {
    id: '2',
    alt: 'Надежность',
    title: 'Надежность',
    text: `Гарантирую выполнение своих обязательств и обеспечиваю высокое качество работы.`,
  },
  {
    id: '3',
    alt: 'Индивидуальный подход',
    title: 'Индивидуальный подход',
    text: `Учитываю пожелания каждого клиента, чтобы сделать отличные фотографии.`,
  },

  {
    id: '4',
    alt: 'Высокое качество фотографий',
    title: 'Высокое качество фотографий',
    text: `Использую современное оборудование и программное обеспечение для получения качественных фотографий.`,
  },
  {
    id: '5',
    alt: 'Быстрое выполнение заказа',
    title: 'Быстрое выполнение заказа',
    text: `Работаю быстро и эффективно. В большинстве случаев Вы получаете готовые фотографии в течение 24 часов после съёмки.`,
  },
];
