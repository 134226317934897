export const titleHelmet = 'Стоимость фотосъёмки недвижимости в Москве';

export const description =
  'Фотосъёмка недвижимости в Москве и Московской области. Минимальный заказ 10 фото - 2000₽. Готовые фотографии в течение 24 часов.';

export const title = 'Фотосъёмка недвижимости для продажи или сдачи в аренду';

export const SERVICE_ITEMS = [
  {
    id: '0',
    figureTitle: 'Одна готовая фотография',
    figcaption: '200₽*',
    listTitle: 'В стоимость входят:',
    serviceList: [
      '- Выезд в пределах МКАД',
      '- Фотосъёмка до 1 часа',
      '- Цветокоррекция фотографий',
      '- Исходные RAW файлы',
      '- Хранение файлов в облаке 30 дней',
    ],
    articleNote: 'Минимальный заказ 10 фото - 2000₽',
  },
  // {
  //   id: '0',
  //   figureTitle: 'Экспресс',
  //   figcaption: '2900Р*',
  //   listTitle: 'В стоимость входят:',
  //   serviceList: [
  //     '- Выезд в пределах МКАД',
  //     '- Фотосъёмка до 30 минут',
  //     '- До 15 готовых фотографий',
  //     '- Исходные RAW файлы',
  //     '- Хранение файлов в облаке 7 дней',
  //   ],
  //   // articleNote: '*Подходит для объектов без отделки и студий',
  // },
  // {
  //   id: '1',
  //   figureTitle: 'Стандарт',
  //   figcaption: '4900Р*',
  //   listTitle: 'В стоимость входят:',
  //   serviceList: [
  //     '- Выезд в пределах МКАД',
  //     '- Фотосъёмка до 1 часа',
  //     '- До 30 готовых фотографий',
  //     '- Исходные RAW файлы',
  //     '- Хранение файлов в облаке 30 дней',
  //   ],
  //   // articleNote: '*Подходит для 1-4 комнатных квартир',
  // },
  // {
  //   id: '2',
  //   figureTitle: 'Максимум',
  //   figcaption: '7900Р*',
  //   listTitle: 'В стоимость входят:',
  //   serviceList: [
  //     '- Выезд в пределах МКАД',
  //     '- Фотосъёмка до 2 часов',
  //     '- До 50 готовых фотографий',
  //     '- Исходные RAW файлы',
  //     '- Хранение файлов в облаке 180 дней',
  //   ],
  //   // note: '*Подходит для домов и квартир, где 5 комнат и больше',
  // },

  // {
  //   id: '3',
  //   figureTitle: 'Фотосъёмка 2-к квартиры',
  //   figcaption: '5000Р*',
  //   listTitle: 'В стоимость входят:',
  //   serviceList: [
  //     '- Выезд в пределах МКАД',
  //     '- Фотосъёмка до 1 часа',
  //     '- Цветокоррекция фотографий',
  //     '- Исходные RAW файлы',
  //     '- Хранение файлов в облаке 90 дней',
  //   ],
  // },
  // {
  //   id: '4',
  //   figureTitle: 'Фотосъёмка 3-к квартиры',
  //   figcaption: '6000Р*',
  //   listTitle: 'В стоимость входят:',
  //   serviceList: [
  //     '- Выезд в пределах МКАД',
  //     '- Фотосъёмка до 1 часа',
  //     '- Цветокоррекция фотографий',
  //     '- Исходные RAW файлы',
  //     '- Хранение файлов в облаке 90 дней',
  //   ],
  // },
  // {
  //   id: '5',
  //   figureTitle: 'Фотосъёмка 4-к квартиры',
  //   figcaption: '7000Р*',
  //   listTitle: 'В стоимость входят:',
  //   serviceList: [
  //     '- Выезд в пределах МКАД',
  //     '- Фотосъёмка до 1 часа',
  //     '- Цветокоррекция фотографий',
  //     '- Исходные RAW файлы',
  //     '- Хранение файлов в облаке 180 дней',
  //   ],
  // },
];

export const note = '*Выезд за МКАД рассчитывается дополнительно';
