import { useEffect, useState } from 'react';
import { articlesList, description, title } from './consts';
import BlogArticles from '../BlogArticles/BlogArticles';
import './Blog.css';
import { Helmet } from 'react-helmet';

export default function Blog() {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(articlesList);
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta property='og:title' content={title}></meta>
        <meta property='og:description' content={description}></meta>
      </Helmet>
      <section className='blog' aria-label='Статьи про фотосъемку недвижимости'>
        <BlogArticles data={data} />
      </section>
    </>
  );
}
