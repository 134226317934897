import Message from '../Message/Message';
import Questions from '../Questions/Questions';
import { Helmet } from 'react-helmet';
import { title, description } from './consts';

export default function Contacts() {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta property='og:title' content={title}></meta>
        <meta property='og:description' content={description}></meta>
      </Helmet>
      <Questions />
      <Message />
    </>
  );
}
