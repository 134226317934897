import { Link } from 'react-router-dom';
import { navLinksList } from '../../utils/consts';
import './FooterTop.css';

export default function FooterTop() {
  const listItems = navLinksList.map(({ id, link, title }) => (
    <li key={id} className='footer__item'>
      <Link to={link} className='footer__link'>
        {title}
      </Link>
    </li>
  ));

  return <ul className='footer__list'>{listItems}</ul>;
}
