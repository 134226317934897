import './Main.css';

import ScrollUpButton from '../ScrollUpButton/ScrollUpButton';
import PageRoutes from '../../routes/routes';

export default function Main() {
  return (
    <main className='main'>
      <PageRoutes />
      <ScrollUpButton />
    </main>
  );
}
