import { Route, Routes } from 'react-router-dom';

import Home from '../components/Home/Home';
import Blog from '../components/Blog/Blog';
import BlogArticle from '../components/BlogArticle/BlogArticle';
import Services from '../components/Services/Services';
import Contacts from '../components/Contacts/Contacts';
import PageNotFound from '../components/PageNotFound/PageNotFound';
import { decodedBlogUrl, decodedServicesUrl, decodedСontactsUrl } from '../utils/consts';

export default function PageRoutes() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />

      <Route path={decodedBlogUrl}>
        <Route index element={<Blog />} />
        <Route path=':articleId' element={<BlogArticle />} />
      </Route>

      <Route path={decodedServicesUrl} element={<Services />} />

      <Route path={decodedСontactsUrl} element={<Contacts />} />

      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );
}
