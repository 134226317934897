import './Message.css';
import { socialLinks } from '../../utils/consts';
import MessageLink from '../MessageLink/MessageLink';

export default function Message() {
  const listItems = socialLinks.map((item) => <MessageLink item={item} key={item.id} />);

  return (
    <section className='message' aria-label='Ссылки на мессенджеры для отправки сообщений'>
      <p className='message__title'>
        Есть&nbsp;вопрос? Желаете&nbsp;заказать&nbsp;фотосъёмку? Пишите&nbsp;в&nbsp;мессенджер!
      </p>

      <ul className='message__links'>{listItems}</ul>
    </section>
  );
}
