import './Home.css';
import Lead from '../Lead/Lead';
import AboutMe from '../AboutMe/AboutMe';
import Dignity from '../Dignity/Dignity';
import PhotoGallery from '../PhotoGallery/PhotoGallery';

import { Helmet } from 'react-helmet';
import { description, title } from './consts';

export default function Home() {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta property='og:title' content={title}></meta>
        <meta property='og:description' content={description}></meta>
      </Helmet>
      <Lead />
      <AboutMe />
      <Dignity />
      <PhotoGallery />
    </>
  );
}
