import { Link } from 'react-router-dom';
import './BlogItem.css';
import { buttonText } from './consts';

export default function BlogItem({ item }) {
  const { title, link, image } = item;

  return (
    <li className='blog__item'>
      <img src={image} alt={title} className='blog__item-image' />
      <p className='blog__item-text'>{title}</p>
      <Link to={link} className='blog__item-button'>
        {buttonText}
      </Link>
    </li>
  );
}
